.linkList{
  width: 100%;
  min-height: 100vh;
  background: #0e0e16;
}

.expcont{
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fill, 360px);
  grid-row-gap: 6em;

  font-weight: bolder;
  padding: 100px;
}

a{
  font-size: 1.2em;
  color: #606c79;
  text-decoration: none;
}

.card{
  width: 360px;
  /* margin-bottom: 6em; */
  padding-bottom: 16px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 4px 4px 12px #0a0a11;
  transition: all 200ms ease-in-out;
}

.card:hover{
  box-shadow: 8px 8px 24px #0a0a11;
}

.card a{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
}

.card img{
  max-width: 100%;
  filter: brightness(1.25);
  margin-bottom: 12px;
}

.labtitle{
  text-align: center;
  font-size: 3em;
  font-weight: 600;
  color: #a5a6d1;
  padding-top: 64px;
}
