.bezbox{
  height: 100vh;
  overflow: hidden;
}

.valbox{
  position: absolute;
  top: 10%;
  left: 10%;
  font-size: 1.6em;
  color: #b1bdc8;
}
